<template>
	<v-dialog v-model="uploadMenu" width="500">
		<template v-slot:activator="{ on }">
			<v-list-item v-on="on">
				<v-list-item-title>{{ $t('subscriptions.uploadInvoice') }}</v-list-item-title>
			</v-list-item>
		</template>
		<v-card rounded="xl">
			<v-card-title>
				{{ $t('explorer.uploadFiles') }}
				<v-spacer />
				<v-btn icon @click="uploadMenu = false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-file-input v-model="document" :label="$t('explorer.selectFiles')" />
				<template v-if="document">
					<v-subheader class="text-subtitle">{{ $t('explorer.readyToUpload') }}</v-subheader>
					<p>{{ document.name }}</p>
				</template>
			</v-card-text>

			<v-card-actions>
				<v-spacer />
				<v-btn color="secondary" text @click="clearDocument()">
					{{ $t('settings.clear') }}
				</v-btn>
				<v-btn color="primary" text @click="callUploadInvoice()">
					{{ $t('settings.upload') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'SubscriptionInvoiceUpload',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		},
		payment: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			loading: false,
			uploadMenu: false,
			document: null
		}
	},
	computed: {
		...mapGetters({
			subscription: 'subscriptions/subscription'
		})
	},
	methods: {
		switchViewMode() {
			this.uploadMenu = !this.uploadMenu
		},
		clearDocument() {
			this.document = []
		},
		callUploadInvoice() {
			this.uploadInvoice({ paymentID: this.payment.id, document: this.document }).then(({ success }) => {
				if (success) {
					this.document = []
					this.fetchSubscription(this.subscription.id)
				}
				this.uploadMenu = false
			})
		},
		...mapActions('subscriptions', ['uploadInvoice', 'fetchSubscription'])
	}
}
</script>
